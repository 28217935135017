import React, { useEffect, useState } from "react";
import firebaseApp from "../../firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import { useParams } from "react-router-dom";
import "../../styles/StudentView.css";
import Answers from "./Answers.jsx";
import IsLoading from "../IsLoading";
import PieMaker from "../PieChart/PieMaker";
import ErrorFeedback from "../adminComponents/ErrorFeedback";
import colours from "../../utils/colours";

const database = getDatabase(firebaseApp);

const StudentView = () => {
  console.log("<-- Student View Render")
  const { seshId } = useParams();
  // const sessionRef = ref(database, `data/sessions/${seshId}`);

  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([]);
  const [answerRevealed, setAnswerRevealed] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [userAnswer, setUserAnswer] = useState("");
  const [hasVoted, setHasVoted] = useState(false);
  const [pieData, setPieData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [questionAsked, setQuestionAsked] = useState(false);

  /*
	When realtime db updates, set the question state
	the state update will trigger the useEffect:
		- set the different state to reflect the data from new poll in the same session, including the correct answer 
		- re-enable the vote buttons so student can vote on new poll
	*/
  // setting pollData info (questions and answers)

  useEffect(() => {
    console.log("<-- First useEffect")
    setUserAnswer("");
    if (localStorage.getItem("userVotingInfo")) {
      const { question_id, has_voted, user_answer } = JSON.parse(
        localStorage.getItem("userVotingInfo")
      );
      if (questionId && questionId === question_id && has_voted) {
        setHasVoted(true);
        setUserAnswer(() => user_answer);
      } else if (questionId && questionId !== question_id && has_voted) {
        // if question id state and localstorage don't match but has_voted is true, reset has voted state and voteInfo in localstorage
        setHasVoted(false);
        const userVoteInfo = {
          question_id: question_id,
          has_voted: false,
        };
        localStorage.setItem("userVotingInfo", JSON.stringify(userVoteInfo));
      }
    }
  }, [questionId]);

  useEffect(() => {
    console.log("<-- second useEffect")
    setIsError(false);
    setAnswerRevealed(false);
    try {
      onValue(ref(database, `data/sessions/${seshId}`), (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setQuestionAsked(data.sesData.questionAsked);
        }

        if (data) {
          console.log("if we have some data - onValue - line 73")
          if (!questionAsked || !data.sesData.questionAsked) {
            console.log("if no question asked OR no question in database - line 75")
            setIsLoading(true);
          } else {
            console.log("else block - 78")
            const question = data.pollData.question;

            const answerCollection = [];
            for (const key in data.pollData.answers) {
              const answerData = data.pollData.answers[key];
              answerCollection.push(answerData);
              if (answerData.isCorrect) setCorrectAnswer(answerData.answer);
            }

            const questionId = data.pollData.question_id;
            const answerRevealed = data.pollData.reveal;

            setQuestion(question);
            setAnswers(answerCollection);
            setAnswerRevealed(answerRevealed);
            setQuestionId(questionId);
            setIsLoading(false);
          }
        } else {
          console.log("if no data from database - line 98")
          setIsError(true);
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.log("catchy watchy woo")
      if (err) setIsError(true);
    }
  }, [seshId, questionAsked, questionId]);

  //colors for pie chart for student
  useEffect(() => {
    setIsError(false);
    try {
      onValue(ref(database, `data/sessions/${seshId}`), (snapshot) => {
        const data = snapshot.val();
        if (data && question) {
          setIsError(false);
          setPieData(() => {
            const answers = [];
            for (const key in data.pollData.answers) {
              //if the answer is correct and the answers have been revealed
              if (data.pollData.answers[key].isCorrect && answerRevealed) {
                answers.push({
                  name: data.pollData.answers[key].answer,
                  value: data.pollData.answers[key].votes,
                  fill: "#3dd980",
                });
              } else if (data.pollData.answers[key].answer === userAnswer) {
                answers.push({
                  name: data.pollData.answers[key].answer,
                  value: data.pollData.answers[key].votes,
                  fill: "#786be4",
                });
              } else {
                answers.push({
                  name: data.pollData.answers[key].answer,
                  value: data.pollData.answers[key].votes,
                  fill: `${colours[key]}50`,
                });
              }
            }
            return answers;
          });
        } else {
          setIsError(true);
        }
      });
    } catch (error) {
      if (error) setIsError(true);
    }
  }, [userAnswer, answerRevealed, seshId, question]);

  return (
    <>
      {isLoading ? (
        <IsLoading type={"cylon"} />
      ) : isError ? (
        <ErrorFeedback code={404} error="No current session" />
      ) : (
        <div id="container">
          <div id="answers">
            <h2 id="question">{question}</h2>

            <Answers
              answers={answers}
              answerRevealed={answerRevealed}
              correctAnswer={correctAnswer}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              setHasVoted={setHasVoted}
              hasVoted={hasVoted}
              database={database}
              seshId={seshId}
              questionId={questionId}
            />

            <PieMaker
              answers={pieData}
              revealChart={hasVoted}
              renderStudentLabel={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StudentView;
