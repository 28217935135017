import React, { useEffect, useState } from "react";
import { Legend, Pie, PieChart } from "recharts";
import "../../styles/PieMaker.css";

import IsLoading from "../IsLoading";

const PieMaker = ({ answers, revealChart, renderStudentLabel }) => {
	const [hasVotes, setHasVotes] = useState(false);
	const [totalVotes, setTotalVotes] = useState(0);

	const votesCast = answers.reduce((previousAnswer, currentAnswer) => {
		return previousAnswer + currentAnswer.value;
	}, 0);

	useEffect(() => {
		const votes = answers.filter((answer) => answer.value > 0);
		// this could be done better - but stores amount of votes in state and updates on every new answer
		let voteTotal = 0;
		votes.forEach((vote) => (voteTotal += vote.value));

		setTotalVotes(() => voteTotal);

		votes.length ? setHasVotes(true) : setHasVotes(false);
	}, [answers]);

	const RADIAN = Math.PI / 180;
	const renderLabel = function ({ value, cx, cy, outerRadius, innerRadius, midAngle }) {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		if (value === 0) {
			return;
		}

		return (
			<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
				{`${((value / votesCast) * 100).toFixed(0)}%`}
			</text>
		);
	};

	return (
		<>
			<div className={revealChart ? "pie__chart" : "hidden__pie__chart"}>
				{hasVotes ? (
					<>
						{renderStudentLabel ? (
							<PieChart width={390} height={450} marginRight={30}>
								<Legend
									chartWidth={690}
									chartHeight={500}
									payload={[{}]}
									iconSize={0}
									layout={"horizontal"}
								/>
								<Pie
									data={answers}
									dataKey="value"
									nameKey="name"
									cx="50%"
									cy="50%"
									outerRadius={170}
									label={renderLabel}
									labelLine={false}
									isAnimationActive={false}
								/>
							</PieChart>
						) : (
							<>
								<PieChart width={390} height={450} marginRight={30}>
									<Pie
										data={answers}
										dataKey="value"
										nameKey="name"
										cx="50%"
										cy="50%"
										outerRadius={170}
										label={renderLabel}
										labelLine={false}
										isAnimationActive={false}
									/>
								</PieChart>
							</>
						)}
						{totalVotes && !renderStudentLabel ? (
							<>
								<ul className="legend__list">
									{answers.map((answer) => {
										return (
											<li
												key={answer.name}
												style={{ backgroundColor: answer.fill }}
												className="legend__item"
											>
												<span>{answer.name} </span>
												<span className="legend__value">{answer.value}</span>
											</li>
										);
									})}
									<li key="totalVotesKey" className="legend__item">
										<span className="legend__total">Total Votes</span>
										<span className="legend__value legend__total">{totalVotes}</span>{" "}
									</li>
								</ul>
							</>
						) : null}
					</>
				) : (
					<div className="vote__loading">
						<IsLoading type={"spinningBubbles"} />
						<h3 style={{ color: "black" }}>Waiting For Votes</h3>
					</div>
				)}
			</div>
		</>
	);
};

export default PieMaker;
