// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBFZFSjG6Dhi844U5uIh1G_vq4eMhF6_yY",
  authDomain: "polling-app-f4fce.firebaseapp.com",
  databaseURL:
    "https://polling-app-f4fce-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "polling-app-f4fce",
  storageBucket: "polling-app-f4fce.appspot.com",
  messagingSenderId: "742019428220",
  appId: "1:742019428220:web:1f134f9fe799b434a57ef9",
  measurementId: "G-2SXKKLVZM9",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
