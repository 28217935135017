import "./styles/App.css";
import StudentView from "./components/student/StudentView";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import LoginView from "./components/adminComponents/LoginView";
import ErrorFeedback from "./components/adminComponents/ErrorFeedback";
import { useContext, useState } from "react";
import CreatePoll from "./components/tutor/CreatePoll";
import PollAdmin from "./components/tutor/PollAdmin";
import { UserContext } from "./contexts/userContext";

function App() {
  const [sessionId, setSessionId] = useState("");
  const [sessionName, setSessionName] = useState("");
  const { passportSession, isLocal } = useContext(UserContext);
  const canShowAdminRoutes =
    isLocal || passportSession.scopes.poll?.includes("ADMIN");

  const canShowStudentRoutes = isLocal || passportSession;

  return (
    <div className="App">
      <Router>
        <NavBar />
        <Routes>
          {canShowStudentRoutes && (
            <Route path="/poll/:seshId" element={<StudentView />}></Route>
          )}

          {canShowAdminRoutes && (
            <>
              <Route
                path="/tutor"
                element={
                  <CreatePoll
                    sessionId={sessionId}
                    setSessionId={setSessionId}
                    sessionName={sessionName}
                    setSessionName={setSessionName}
                  />
                }
              ></Route>
              <Route
                path="/"
                element={
                  <CreatePoll
                    sessionId={sessionId}
                    setSessionId={setSessionId}
                    sessionName={sessionName}
                    setSessionName={setSessionName}
                  />
                }
              ></Route>
              <Route
                path="/tutor/:sessionIdFromParams"
                element={
                  <CreatePoll
                    sessionId={sessionId}
                    setSessionId={setSessionId}
                    sessionName={sessionName}
                    setSessionName={setSessionName}
                  />
                }
              ></Route>
              <Route
                path="/tutor/:sessionIdFromParams/admin"
                element={
                  <PollAdmin
                    sessionId={sessionId}
                    setSessionId={setSessionId}
                    sessionName={sessionName}
                    setSessionName={setSessionName}
                  />
                }
              ></Route>
            </>
          )}

          <Route path="/login" element={<LoginView />}></Route>
          <Route
            path="/"
            element={
              <ErrorFeedback
                code={"404"}
                error={"Your tutor will send a link for the next session"}
              />
            }
          ></Route>
          <Route path="*" element={<ErrorFeedback code={"404"} />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
