const colours = [
  "#414042",
  "#F153DD",
  "#B0B0B0",
  "#D7A1D7",
  "#786be4",
  "#3AC8D6",
  "#E8B43E",
  "#005f73",
  "#bb3e03",
  "#0a9396",
  "#ca6702",
  "#1632FF",
  "#FF0D1A",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
]

export default colours
