import React from "react"
import "../styles/NavBar.css"
import { Link } from "react-router-dom"

import logo from "../images/tertiary-logo.png"

// import firebaseApp from "../firebase"

const NavBar = () => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  // const navigate = useNavigate()

  // const changeSidebar = () => {
  //   setIsSidebarOpen((isOpen) => {
  //     return !isOpen
  //   })
  // }

  return (
    <nav className="nav">
      <img src={logo} alt="northcoders logo" className="logo"></img>

      <Link className="home__link__container" to="/">
        <h1 className="grey-1 align-left home__link">NorthPoll</h1>
      </Link>
      {/* <>
        <div
          id="mySidebar"
          className={`sidebar sidebar__open__${isSidebarOpen}`}
        >
          {user ? <p onClick={logout}>Logout</p> : <p onClick={login}>Login</p>}
        </div>
        <div id="main">
          <button className="openbtn" onClick={changeSidebar}>
            &#9776;
          </button>
        </div>
      </> */}
    </nav>
  )
}

export default NavBar
