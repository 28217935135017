import { increment, ref, set } from "firebase/database";
import React from "react";
import colours from "../../utils/colours";

import { handleRevealedAnswer, handleUnrevealedAnswer } from "../../utils";

const Answers = ({ answers, correctAnswer, answerRevealed, userAnswer, setUserAnswer, hasVoted, setHasVoted, database, seshId, questionId }) => {
	// state for storing users selected answer

	//VOTE FUNCTION
	const vote = ({ answer, isCorrect }, answerKey) => {
		set(ref(database, `data/sessions/${seshId}/pollData/answers/${answerKey}`), {
			answer,
			isCorrect,
			votes: increment(1),
		});
	};

	const handleUserAnswerClick = (event, answerData, index) => {
		const userVoteInfo = {
			question_id: questionId,
			has_voted: true,
			user_answer: event.target.innerText,
		};

		localStorage.setItem("userVotingInfo", JSON.stringify(userVoteInfo));

		setHasVoted(true);
		setUserAnswer(() => event.target.innerText);
		vote(answerData, index);
	};

	return (
		<div id="answersList">
			{answers.map((answerData, index) => {
				return (
					<button
						key={index}
						style={userAnswer ? { backgroundColor: `${colours[index]}4e` } : null}
						className={
							// IF ANSWER HAS BEEN REVEALED?
							answerRevealed
								? handleRevealedAnswer(userAnswer, correctAnswer, answerData.answer)
								: // IF ANSWER HAS NOT BEEN REVEALED
								  handleUnrevealedAnswer(userAnswer, answerData.answer)
						}
						disabled={hasVoted}
						onClick={(event) => handleUserAnswerClick(event, answerData, index)}
					>
						{answerData.answer}
					</button>
				);
			})}
		</div>
	);
};

export default Answers;
