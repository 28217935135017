// ANSWER HAS BEEN REVEALED
const handleRevealedAnswer = (userAnswer, correctAnswer, currentAnswer) => {
  // if no user answer
  if (!userAnswer) return "voteButton"
  //is user answer same as current answer?
  if (userAnswer === currentAnswer) {
    // if so, is it the correct answer?
    if (userAnswer === correctAnswer) {
      return "userCorrect"
    }
    //if incorrect answer
    return "userIncorrect"
  }

  //if unselected answer is correct?
  if (currentAnswer === correctAnswer) {
      // not picked but the right answer
    return "userWrongButAnswerCorrect"
  }
  // unselected incorrect answer
  return "unselectedVoteButton"
}
 

// ANSWER HAS NOT BEEN REVEALED
const handleUnrevealedAnswer = (userAnswer, currentAnswer) => {
  // if no user answer selected
  if (!userAnswer) return "voteButton"
  //if user answer is the currentAnswer
  if (userAnswer === currentAnswer) {
    return "selectedVoteButton"
  }
  // if answer has not been selected by the user
  return "unselectedVoteButton"
}

module.exports = { handleRevealedAnswer, handleUnrevealedAnswer }
