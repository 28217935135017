import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import "../../styles/LoginView.css"
import firebaseApp from "../../firebase.js"
import { signInWithEmailAndPassword, getAuth, signOut } from "firebase/auth"

const auth = getAuth(firebaseApp)

const LoginView = () => {
  const [user, loading] = useAuthState(auth)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
  }, [user, loading, navigate])

  const logInWithEmailAndPassword = (event) => {
    event.preventDefault()
    signInWithEmailAndPassword(auth, email, password).catch((err) => {
      console.error(err)
      alert(err.message)
    })
  }

  return (
    <div className="contentBox align-left">
      <h2 className="blue-1">Login</h2>
      {user ? (
        <>
          <Link to={"/tutor"}>CLICK ME </Link>
          <button
            onClick={() => {
              signOut(auth)
            }}
            style={{ display: "block" }}
          >
            logout
          </button>
        </>
      ) : (
        <form onSubmit={logInWithEmailAndPassword}>
          <label className="login__label" htmlFor="email">
            email
          </label>
          <input
            className="login__input"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="login__label" htmlFor="password">
            password
          </label>
          <input
            className="login__input"
            id="password"
            type={"password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button className="primaryButton">Login</button>
        </form>
      )}
    </div>
  )
}

export default LoginView
