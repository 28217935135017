import { createContext, useEffect, useState } from "react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import axios from "axios";
import IsLoading from "../components/IsLoading";
export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
	const [passportSession, setPassportSession] = useState(null);
	const [isLocal, setIsLocal] = useState(false);

	useEffect(() => {
		if (window.location.hostname !== "localhost") {
			axios
				.get("https://auth-v2.northcoders.com/googleAuth", {
					withCredentials: true,
				})
				.then(({ data }) => {
					const { token, session } = data;
					const auth = getAuth();
					setPassportSession(session);
					return signInWithCustomToken(auth, token);
				})
				.catch(() => {
          const currentLocation = window.location.href;
          window.location.href = `https://passport.northcoders.com?origin=${currentLocation}`
        });
		} else {
			setIsLocal(true);
		}
	}, []);

	if (!passportSession && !isLocal) return <IsLoading type={"spinningBubbles"} />;

	return <UserContext.Provider value={{ passportSession, isLocal }}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
