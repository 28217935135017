import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Home.css";

const ErrorFeedback = ({ error, code, link }) => {
  const handleErrorCode = (code) => {
    if (code === 401) {
      return (
        <p>
          Please login{" "}
          <span>
            <Link to="/login">here</Link>
          </span>
        </p>
      );
    } else {
      return <p>{error ?? "Nothing to see here!"}</p>;
    }
  };

  return (
    <div className="contentBox">
      <h2 className="blue-1">
        {">"}
        {code}
      </h2>

      {handleErrorCode(code)}
    </div>
  );
};

export default ErrorFeedback;
